/*****************************
	Button
*****************************/

.btn {
	transition: all 0.3s ease-in-out;
}

button {
	outline: medium none !important;
	color: $primary;
}

// btn
.btn {
	font-size: 14px;
	font-weight: 600;
	padding: 12px 30px;
	border-radius: 0px;

	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none;
				outline: none;
			}

		}

		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none;
					outline: none;
					color: $white;
				}

			}

		}

	}

	&:hover {
		box-shadow: none;
		outline: none;
	}

	&:focus {
		box-shadow: none;
		outline: none;
		color: $white;
	}

	&:active {
		box-shadow: none;
		outline: none;
		color: $white;
	}

}

.show {
	>.btn.dropdown-toggle {
		&:focus {
			box-shadow: none;
			outline: none;
			color: $white;
		}

	}

	>.btn-primary.dropdown-toggle {
		background: $primary;
		border-color: $primary;
	}

}

.btn-primary {
	background: $primary;
	border-color: $primary;

	&:hover {
		background: darken($primary, 6%);
		border-color: darken($primary, 6%);
	}
	&:focus {
		background: darken($primary, 6%);
		border-color: darken($primary, 6%);
	}
	&:active {
		background: darken($primary, 6%);
		border-color: darken($primary, 6%);
	}

	&:not(:disabled) {
		&:not(.disabled).active {
			background: $primary;
			border-color: $primary;
		}

		&:not(.disabled) {
			&:active {
				background: $primary;
				border-color: $primary;
			}

		}

	}

}

.btn-dark {
	background: $gray-9;
	border-color: $gray-9;

	&:hover {
		background: lighten($gray-9, 5%);
		border-color: lighten($gray-9, 5%);
	}

}

.btn-dark:not(:disabled):not(.disabled):active:focus {
	color: $primary;
}

.btn {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				color: $white;
			}

		}

	}

	i {
		padding-right: 10px;
	}

	+ {
		.btn {
			margin-left: 3px;
		}

	}

}

.btn-link {
	color: $primary;
	display: flex;
	align-items: center;
	text-decoration: none;

	&:hover {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}

	&:focus {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}

}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

.btn-white {
	background: $white;
	border-color: $white;
	color: $gray-9;

	&:hover {
		background: $gray-2;
		border-color: $gray-2;
	}

}

.btn.btn-sm {
	padding: 6px 24px;
}

.btn.btn-md {
	padding: 9px 26px;
}

.btn.btn-lg {
	padding: 14px 30px;
}

.btn.btn-xl {
	padding: 16px 40px;
}

.btn-app {
	display: flex;
	i {
		font-size: 40px;
	}

}

.btn.btn-app {
	margin-left: 0px;
}

.btn-outline {
	border: 1px solid $border-color;

	&:hover {
		background: $primary;
		border-color: $primary;
		color: $white;
	}

	&:focus {
		background: $primary;
		border-color: $primary;
		color: $white;
		text-decoration: none;
		outline: none;
	}

}

.btn-light {
	background: $gray-1;
	box-shadow: 1px 1px 14px 0px rgba($gray-8, 0.04);
}

.btn-light:hover {
	background: $gray-2;
	box-shadow: 1px 1px 14px 0px rgba($gray-8, 0.19);
	border: 1px solid transparent;
}

.btn-outline-primary {
	background: transparent;
	color: $primary;
	border: 1px solid $primary;

	&:not(:disabled) {
		&:not(.disabled).active {
			background: transparent;
			border-color: $primary;
			color: $white;
		}

		&:not(.disabled) {
			&:active {
				background: transparent;
				border-color: $primary;
				color: $primary !important;
			}

		}

	}

	&:hover {
		background: $primary;
		color: $white;
		border-color: $primary;
	}

	&:focus {
		background: $primary;
		border-color: $primary;
		color: $white;
		text-decoration: none;
		outline: none;
	}

}

.btn-white:hover,
.btn-white:focus {
	background: $primary !important;
	color: $white !important;
	border-color:$primary !important;
}
