/*****************************
	Testimonial
*****************************/
.testimonial-item {
	.testimonial-content {
		font-size: 28px;
		font-style: italic;
		font-weight: 700;
		position: relative;
		p {
			font-style: normal !important;
			font-size: 16px;
			font-weight: 500;
			color: $gray-4;
			margin-top: 15px;
		}
	}
	.testimonial-name {
		.quotes {
			font-size: 40px;
			line-height: 50px;
			margin-bottom: 10px;
			color: darken($gray-1, 10%);
		}
	}
	.avatar {
		width: 100px !important;
		height: 100px !important;
		display: inline-block;
		img {
			height: 100%;
			object-fit: cover;
		}
	}
}


/* testimonial 02 */
.testimonial-style-02 {
	.testimonial-content {
			background-color: $primary;
			padding: 30px;
			position: relative;
		p {
			font-style: normal !important;
			font-size: 16px;
			font-weight: 400;
			color: $white;
		}
		&:before {
			position: absolute;
			content: "";
			bottom: -9px;
			transition: all 0.5s ease-in-out;
			width: 0;
			height: 0;
			left: 40px;
			transform: translateX(-50%);
			z-index: 1;
			border-style: solid;
			border-width: 10px 10px 0 10px;
			border-color: $primary transparent transparent transparent;
			opacity: 1;
		}
	}
	.testimonial-author {
		margin-top: 30px;
		display: flex;
		align-items: center;
		.testimonial-avatar {
			margin-right: 20px;
			img {
				height: 80px;
				object-fit: cover;
			}
		}
	}
	.quotes {
		display: block;
		margin-bottom: 15px;
		font-size: 30px;
		line-height: 1;
	}
}
