/*****************************
	Shortcodes
*****************************/
/*****************************
	Accordion
*****************************/
.accordion {
	.accordion-item {
		.accordion-title {
			margin-bottom: 15px;
			font-size: 16px;
			font-weight: 500;
			.collapsed {
				color: $gray-8;
			}

		}

		.accordion-content {
			padding-bottom: 25px;
		}

	}

}

/* Accordion Style */
.accordion-style {
	.card {
		border: 1px solid $border-color;
		margin-bottom: 10px;
		border-radius: 0px;

		&:last-child {
			margin-bottom: 0px;
		}

	}

	.card-header {
		background: transparent;
		border-bottom: none;
    padding: 0.75rem 1.25rem;

		.accordion-title {
			display: flex;
			align-items: center;

			i {
				font-size: 12px;
				margin-left: auto;
				padding-right: 0;
				align-self: center;
				margin-top: 7px;
			}

		}

	}

	.accordion-content {
		border-top: 1px solid $border-color;
	}

.accordion-title {
	.btn {
		display: block;
		color: $primary;
		font-size: 16px;
		padding: 0px;
		text-decoration: none;
		width: 100%;
		text-align: left;

		+ {
			i {
				color: $primary;

			}

		}

	}
}
	.btn.collapsed {
		color: $gray-8;

		+ {
			i {
				color: $gray-8;
			}

		}

	}

}

.accordion-style .btn.collapsed:hover,
.accordion-style .btn {
	color: $primary;
}
.accordion-style .btn{
	box-shadow:none;
}
.accordion-style .btn-light:hover{
	box-shadow: none;
}
.accordion-style .btn.btn-link:hover{
	color: $primary;
}

.accordion-style .accordion-content li {
	margin-bottom: 10px;
}

.accordion-style .accordion-content li:last-child {
	margin-bottom: 0px;
}
