/*****************************
	Select2
*****************************/
.select2-results__option {
	transition: all 0.2s ease-in-out;
}


.select2-container {
	margin-left: 0 !important;
	.select2-selection--single {
		height: auto;
		outline: none;

		.select2-selection__rendered {
			padding-left: 0px;
		}

	}

}

.select2-container--default {
	.select2-selection--single {
		border-radius: 0px;
		border: none;

		.select2-selection__rendered {
			color: $gray-8;
			font-weight: bold;
			line-height: inherit;
			text-transform: capitalize;
		}

	}

}

.bigdrop {
	min-width: 196px !important;
}

.select2-results__options {
	padding: 8px 2px;
}

.select2-container {
	width: 100% !important;
}

.select2-container--default {
	.select2-results__option--highlighted {
		transition: all 0.2s ease-in-out;
	}
}

.select2-container--default {
	.select2-results__option--highlighted[aria-selected] {
		padding-left: 10px;
	}
}

/* Select Dropdown Arrow */
.select2-container--default {
	.select2-selection--single {
		.select2-selection__arrow {
			top: 1px;
			right: 0px;
			height: 21px;
			width: 14px;

			b {
				border: none;
			}

			&:before {
				content: "\f078";
				font-family: "Font Awesome 6 Free";
				font-style: normal;
				font-weight: 900;
				color: $primary;
			}

		}

	}

	.select2-search--dropdown {
		padding: 0;

		.select2-search__field {
			border: 1px solid $border-color;
		}

	}

	.select2-results__option[aria-selected=true] {
		background-color: $gray-1;
	}

	.select2-results__option--highlighted[aria-selected] {
		background: rgba($primary, 0.1);
		color: $primary;
	}

}

.select2-dropdown {
	border: none;
	border-radius: 0px;
}

.select2-container--default {
	.select2-selection--single {
		border: 1px solid $border-color;
		height: 50px;
		padding: 15px 20px;
		.select2-selection__arrow {
			top: 16px;
			right: 20px;
		}

	}

}

.select2-results__option[aria-selected] {
	text-transform: capitalize;
}

.select2-container--default {
	.select2-results {
		>.select2-results__options {
			border: none;
		}
	}
}

.select2-container--default {
	.select2-selection--single {
		.select2-selection__rendered {
			color: $gray-4;
			font-weight: 600;
		}
	}
}

.select2-container--open {
	.select2-dropdown {
		background: lighten($gray-1, 1%);
		padding: 7px;
		border: 1px solid $border-color;
	}
}
.select2-search--dropdown {
	.select2-search__field {
		border: 1px solid $border-color;
		padding: 4px 10px;
		box-shadow: 0px 2px 5px rgba($black, 0.04);
	}
}

.select2-container--default.select2-container--open {
	.select2-selection--single {
		border-color: $primary;
	}
}


.select-border {
	.select2-container--default {
		.select2-selection--single {
			.select2-selection__arrow {
				top: 16px;
				&:before {
					color: $gray-3;
				}
			}
		}
	}
}

.bootstrap-datetimepicker-widget{
	 table{
	 	 th.prev{
       span{
       	font-weight: 900 !important;
       }
	 	 }
	 	  th.next{
       span{
       	font-weight: 900 !important;
       }
	 	 }
	 }
}

 tbody {

	  border-top: inherit !important;
 }
.bootstrap-datetimepicker-widget {
	table {
		td.day {
			height: 15px !important;
			line-height: 15px !important;
			width: 30px !important;
			font-size: 14px !important;
			padding: 10px !important;
		}
	}
}


