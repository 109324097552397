/*****************************
  Sidebar
*****************************/
.sidebar {
  .widget {
    border: 1px solid $border-color;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .widget-title {
    border-bottom: 1px solid $border-color;
    padding: 14px 20px;
  }

  .widget-content {
    padding: 14px 20px;
  }
  }

}

.sidebar {
  .search {
    position: relative;
    i {
      position: absolute;
      color: $primary;
      cursor: pointer;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
    .form-control {
      font-weight: 400;
      padding-right: 40px;
      height: 50px;
      padding-left: 15px;
    }
  }
}
.sidebar {
  .locations {
    position: relative;
    i {
      position: absolute;
      color: $primary;
      cursor: pointer;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
    .form-control {
      font-weight: 400;
      padding-right: 40px;
      height: 50px;
      padding-left: 15px;
    }
  }
}

/* Widget */
.widget {
  .widget-title {
    margin-bottom: 20px;
    h6 {
      margin-bottom: 0;
    }
    a {
      color: $gray-8;
    }
  }
}
.widget {
  .widget-collapse {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
}

/* similar-jobs-item */

.similar-jobs-item {
  .job-list {
    border-bottom: 0;
    padding: 0;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      box-shadow: none;
    }
  }
}

/* checkbox */
.widget {
  .widget-content {
    margin-top: 10px;
    .custom-checkbox {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}

/*fill checkbox*/
.widget{
  .fulltime-job{
    .form-check-input{
        background-color: $blue;
        border: 2px solid $blue;
      &:checked{
        background-color: $blue;
        border-color: $blue;
        border: 2px solid $blue;
      }
    }
  }
  .parttime-job{
    .form-check-input{
        background-color: $orange;
        border: 2px solid $orange;
      &:checked{
        background-color: $orange;
        border-color: $orange;
        border: 2px solid $orange;
      }
    }
  }
  .freelance-job{
    .form-check-input{
        background-color: $green;
        border: 2px solid $green;
      &:checked{
        background-color: $green;
        border-color: $green;
        border: 2px solid $green;
      }
    }
  }
 .temporary-job{
   .form-check-input{
      background-color: $red;
      border: 2px solid $red;
      &:checked{
        background-color: $red;
        border-color: $red;
        border: 2px solid $red;
      }
    }
  }
}

.widget {
  ul {
    margin: 0;
    li {
      a {
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
.widget {
  .company-detail-meta {
    ul {
      display:block;
      align-items: center;
      li {
        margin-right: 15px;
        display: inline-block;
        a {
          color: $gray-3;
          font-weight: 600;
          font-size: 12px;
        }
      }
    }
  }
}


.widget {
  .company-detail-meta {
    .share-box {
      li {
        margin-right: 0;
        display: inline-block;
        float: left;
      }
    }
  }
}

.widget {
  .company-detail-meta {
    ul {
      li.linkedin {
        a {
          padding: 15px 20px;
          border: 2px solid $border-color;
          display: inline-block;
          i {
            color: #06cdff;
          }
        }
      }
    }
  }
}

.widget {
  .company-address {
    ul {
      li {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          color: $gray-3;
        }
      }
    }
  }
}

.widget {
  .widget-box {
    padding: 20px 15px;
  }
}

.widget {
  .similar-jobs-item {
    .job-list.jobster-list {
      padding: 15px 10px;
      border: 0;
      margin-bottom: 10px;
    }
     .job-list{
      padding-bottom: 15px;
     }
  }
}

.widget {
  .similar-jobs-item {
    .job-list-logo {
      margin-left: auto;
      flex: 0 0 60px;
      height: 60px;
      width: 60px;
    }
    .job-list-details {
      margin-right: 15px;
      align-self: center;
      .job-list-title {
        h6 {
          margin-bottom: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.widget {
  .similar-jobs-item {
    .job-list.jobster-list {
      .job-list-company-name {
        color: $primary;
      }
    }
  }
}

.widget {
  .docs-content {
    display: flex;
    background: $border-color;
    padding: 30px;
    border-radius: $border-radius;
    .docs-text {
      align-self: center;
      color: $gray-3;
    }
    span {
      font-weight: 600;
    }
    .docs-icon {
      margin-left: auto;
      flex: 0 0 38px;
    }
  }
}

/* Job Detail */
.widget {
  .jobster-company-view {
    ul {
      li {
         border: 1px solid $border-color;
         margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          color: $gray-8;
          align-self: center;
          font-weight: 600;
        }
      }
    }
  }
}



/* secondary menu */
.secondary-menu-sticky-top {
  padding: 10px;
  padding-bottom: 0px;
  border: 1px solid $border-color;
  background: $white;
  margin-bottom: 48px;
  margin-top: -25px;
}

.secondary-menu-sticky-top {
  ul {
    padding: 0px;
    display: flex;
    margin: 0;
    li {
      list-style: none;
      padding-right: 9px;
      padding-bottom: 10px;
      a {
        display: block;
        color: $body-color;
        font-weight: 600;
        text-transform: capitalize;
        padding: 1px 15px;
        &:hover {
          color: $white;
          background: $primary;
        }
      }
      a.active {
        color: $white;
        background: $primary;
      }
    }
  }
}
